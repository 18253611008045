import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'

let root = document.getElementById('root')

if (!root) {
  root = document.createElement('div')
  document.body.appendChild(root)
}

ReactDOM.render(<App />, root)
