import React from 'react'
import cs from 'classnames'

import styles from './nonAuthHeader.module.css'
import serverData from '../../utils/server-data'

const PAYPAL_HOME = `https://${serverData.domain}`
const PAYPAL_LOGO_URL = `https://${serverData.cdnHost}/webstatic/i/sparta/logo/logo_paypal_106x29.png`

const NonAuthHeader = () => {
  return (
    <div className={cs(styles.container)}>
      <a className={cs(styles.logoLink)} href={PAYPAL_HOME}>
        <img src={PAYPAL_LOGO_URL} alt="PayPal Home" />
      </a>
    </div>
  )
}

export default NonAuthHeader
