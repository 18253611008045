import React from 'react'

import serverData, { init as initServerData } from '../../utils/server-data'
initServerData()

const ApplicationContext = React.createContext(serverData)

export const ApplicationContextProvider = ApplicationContext.Provider

export const ApplicationContextConsumer = ApplicationContext.Consumer
