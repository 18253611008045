import React from 'react'
import PropTypes from 'prop-types'
import MerchantHeaderFooter, { COMPONENT_HEADER } from '../merchantHeaderFooter'
import NonAuthHeader from '../nonAuthHeader'

// const Header = () => <MerchantHeaderFooter component={COMPONENT_HEADER} />

const Header = props =>
  props.isUserAuthenticated ? (
    <MerchantHeaderFooter component={COMPONENT_HEADER} />
  ) : (
    <NonAuthHeader component={COMPONENT_HEADER} />
  )

Header.propTypes = {
  isUserAuthenticated: PropTypes.bool,
}

export default Header
