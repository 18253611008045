import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { insertFragment } from '@paypalcorp/manage-client-fragment'
import styles from './default.module.css'

const DefaultLayout = ({
  children,
  isUserAuthenticated,
  nonce,
  Header = null,
  Footer = null,
  ...rest
}) => {
  const feedbackSurvey = useRef()
  useEffect(
    () =>
      feedbackSurvey.current &&
      insertFragment('feedback-fragment', feedbackSurvey.current, nonce) &&
      undefined,
  ) // return undefined so React doesn't try to use `insertFragment`'s return value as a destructor
  return (
    <>
      {Header && <Header isUserAuthenticated={isUserAuthenticated} />}
      <div className={styles.container} {...rest}>
        <div>{children}</div>
      </div>
      <div
        id="feedback-form"
        ref={feedbackSurvey}
        style={{
          position: 'fixed',
          top: '25%',
          right: '0',
          transform: 'translateY(30%)',
          zIndex: '30',
        }}
      />
      {Footer && <Footer />}
    </>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isUserAuthenticated: PropTypes.bool,
  nonce: PropTypes.string,
  Header: PropTypes.elementType,
  Footer: PropTypes.elementType,
}

export default DefaultLayout
