import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Layout, { LAYOUT_NAME } from './layouts'
import AddProduct from './screens/addProduct'
import ShareProduct from './screens/shareProduct'
import Checkout from './screens/checkout'
import Dashboard from './screens/dashboard'
import QRCode from './screens/qrcode'
import { ApplicationContextConsumer } from './components/applicationContext'

import './app.css'

const routes = [
  { path: '/addproduct', component: AddProduct },
  { path: '/shareproduct/:buttonId', component: ShareProduct },
  { path: '/checkout/:buttonId', component: Checkout },
  { path: '/edit/:buttonId', component: AddProduct },
  {
    path: '/dashboard',
    component: Dashboard,
    layout: LAYOUT_NAME.MERCHANT_HEADER_APP_FOOTER,
  },
  { path: '/qrcode', component: QRCode },
]

const AppRoute = ({
  component: Component,
  layout,
  isUserAuthenticated,
  countryLabel,
  currency,
  nonce,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return (
        <ApplicationContextConsumer>
          {context => (
            <Layout
              name={layout}
              isUserAuthenticated={isUserAuthenticated}
              nonce={nonce}
              context={context}
              countryLabel={countryLabel}
              currency={currency}
            >
              <Component {...props} />
            </Layout>
          )}
        </ApplicationContextConsumer>
      )
    }}
  />
)

AppRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.string,
  isUserAuthenticated: PropTypes.bool,
  nonce: PropTypes.string,
  countryLabel: PropTypes.string,
  currency: PropTypes.string,
}

const Router = ({
  requestURI,
  isUserAuthenticated,
  nonce,
  accountType,
  entryPoint,
  ...rest
}) => {
  window.reqToken = rest.reqToken
  return (
    <BrowserRouter basename={requestURI}>
      <Switch>
        {routes.map(route => (
          <AppRoute
            key={route.path}
            isUserAuthenticated={isUserAuthenticated}
            nonce={nonce}
            accountType={accountType}
            entryPoint={entryPoint}
            path={route.path}
            component={route.component}
            layout={route.layout}
            {...rest}
          />
        ))}
        <Redirect push to="/dashboard" />
      </Switch>
    </BrowserRouter>
  )
}

Router.propTypes = {
  requestURI: PropTypes.string,
  isUserAuthenticated: PropTypes.bool,
  nonce: PropTypes.string,
  accountType: PropTypes.string.isRequired,
  entryPoint: PropTypes.string,
  countryLabel: PropTypes.string,
  currency: PropTypes.string,
}

export default Router
