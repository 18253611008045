import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'kepler/lib/components/Loader'

const LoaderWrapper = ({ className, ...props }) => (
  <div className={className}>
    <Loader {...props} />
  </div>
)

LoaderWrapper.propTypes = {
  className: PropTypes.string,
}

export default LoaderWrapper
