import React from 'react'
import PropTypes from 'prop-types'
import { ContextProvider as WorldReadyContextProvider } from '@paypalcorp/worldready-react'
import { WorldReadyProvider } from '@paypalcorp/pp-react-worldready'

import Router from './router'
import applicationData from './components/applicationContext/applicationData'

import './app.css'

const App = (props) => (
  <WorldReadyContextProvider locale={props?.locality?.locale}>
    <WorldReadyProvider>
      <Router {...props} />
    </WorldReadyProvider>
  </WorldReadyContextProvider>
)

App.propTypes = {
  locality: PropTypes.object.isRequired,
}

export default applicationData(App)
