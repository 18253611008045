import React from 'react'
import { ApplicationContextConsumer } from './index'

/**
 * Decorator method to extract `data` from context and inject that prop into `ChildComponent`.
 * @param {Object} ChildComponent - The Child Component that has the injected with the `theme` property.
 * @returns {function} - The Decorated Child Component.
 */
const ApplicationData = ChildComponent =>
  function ApplicationDataComponent(props) {
    return (
      <ApplicationContextConsumer>
        {contexts => <ChildComponent {...props} {...contexts} />}
      </ApplicationContextConsumer>
    )
  }

export default ApplicationData
