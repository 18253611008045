import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { loadScriptFromSrc } from '../../utils/dom'
import applicationData from '../applicationContext/applicationData'
import cs from 'classnames'
import serverData from '../../utils/server-data'

import './merchantHeaderFooter.css'

export const COMPONENT_HEADER = 'header'

export const COMPONENT_FOOTER = 'footer'

const PAYPAL_HEADER_SCRIPT_URL = `https://${serverData.cdnHost}/merchant-hf/stable/hf.js`

const headerDataAttributes = (nonce, country, language) => ({
  'data-country': country || 'US',
  'data-language': language || 'en',
  'data-show-settings': false,
  nonce,
})

const MerchantHeaderFooter = ({ component, nonce, locality = {} }) => {
  const { country, language } = locality
  const merchantHeaderFooterClassName = `merchant-${component}`

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (loading) {
      // if Mutation Observer is present then use it detect header loading.
      if ('MutationObserver' in window) {
        const targetNode = document.getElementsByClassName(
          merchantHeaderFooterClassName,
        )[0]

        const config = { childList: true, subtree: true }

        const callback = (mutationsList, observer) => {
          for (let mutation of mutationsList) {
            if (
              mutation.type === 'childList' &&
              mutation.addedNodes &&
              (targetNode.querySelector('div#footer-fragment') ||
                targetNode.querySelector(
                  'div#merchant-header-main-wrapper-internal',
                ))
            ) {
              observer.disconnect()
              setLoading(false)
              break
            }
          }
        }

        const observer = new MutationObserver(callback)

        observer.observe(targetNode, config)
      } else {
        setTimeout(() => setLoading(false), 3000)
      }

      loadScriptFromSrc({
        src: PAYPAL_HEADER_SCRIPT_URL,
        dataAttributes: headerDataAttributes(nonce, country, language),
        id: 'paypal-header',
      })
    }
    return
  }, [])

  return (
    <div className={`${merchantHeaderFooterClassName}-container`}>
      <div
        className={cs(
          `${merchantHeaderFooterClassName}-loading`,
          !loading && `${merchantHeaderFooterClassName}-loading-done`,
        )}
      />
      <div className={merchantHeaderFooterClassName} />
    </div>
  )
}

MerchantHeaderFooter.propTypes = {
  component: PropTypes.string.isRequired,
  nonce: PropTypes.string,
  locality: PropTypes.object,
}

export default applicationData(MerchantHeaderFooter)
