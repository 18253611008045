// PLEASE THINK TWICE BEFORE CHANGING THIS FILE
// let webpack know how to resolve dependencies for static assets
// and dynamic imports
// @ts-ignore
__webpack_public_path__ = window.webpackPublicPath // wait for the dom to be loaded before attempting to start up the app

document.addEventListener('DOMContentLoaded', () => {
  // this is the only `require` that should be in our app. Using `require` here
  // allows us to defer import resolution until after the DOMContentLoaded
  // event is fired.
  require('./render')
})
