// client-side constants
export const constants = {
  fpti: {
    link: {
      addProduct: 'add_product',
      getLink: 'get_link',
      shareFacebook: 'facebook',
      shareTwitter: 'twitter',
      shareQRCode: 'qr_code',
      buyNow: 'buy_now',
      photoUpload: 'photo_upload',
      createListingReferral: 'create_listing_referral',
      returnToDashboard: 'return_to_dashboard',
      editProduct: 'edit_product',
      deleteProduct: 'delete_product',
      shareProduct: 'share_product',
      listingSubmit: 'listing_submit',
    },
    status: {
      photoUploadSuccess: 'photo_upload_successful',
      photoUploadError: 'photo_upload_error',
      shareCancelled: 'share_cancelled',
      shareDialogOpened: 'share_dialog_opened',
      shareSuccessful: 'share_successful',
      shareError: 'share_error',
      listingSubmitSuccess: 'listing_submit_success',
      listingSubmitError: 'listing_submit_error',
      listingEditSuccess: 'listing_edit_success',
      listingEditError: 'listing_edit_error',
      deleteProductSuccess: 'delete_product_success',
      deleteProductError: 'delete_product_error',
    },
    erpg: {
      missingPhoto: 'missing photo',
    },
  },
  consumerAccountTypes: ['PERSONAL', 'PREMIER'],
  domesticShippingOptions: {
    freeShipping: 'freeShipping',
    flatRate: 'flatRate',
  },
  buttonVariables: {
    productName: 'productName',
    productPrice: 'productPrice',
    imageURLs: 'imageURLs',
    productStockCount: 'productStockCount',
    shippingInfo: 'shippingInfo',
    shippingCost: 'shippingCost',
    productFlatRateShippingPrice: 'productFlatRateShippingPrice',
    productDescription: 'productDescription',
    currency: 'currency',
    productImageURLs: 'productImageURLs',
    productCurrency: 'productCurrency',
  },
}

export default constants
