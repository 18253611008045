/**
 * Method to get the DOM element instance give the ID of the Element.
 * @param {string} id - The id of the element to find and return.
 * @returns {Object} - Returns the DOM Element instance.
 */
export const getElementById = id => document.getElementById(id)

/**
 * Method to create a element in the DOM given a tagName.
 * @param {string} tagName - The tag to created.
 * @returns {Object} - Returns the DOM Element instance
 */
export const createElement = tagName => document.createElement(tagName)

/**
 * Method to Inject a Script Tag given the URL and Data Attributes
 * @param {object} params - The parameters for the function.
 * @param {string} params.src - The source url for loading the script.
 * @param {object} params.dataAttributes - The data attributes that need to attached to the script in the format
 *  e.g. `{ data-attribute-name: value }`
 * @param {string} params.id - The unique identifier associated to the string.
 * @returns {Promise<undefined>} - Returns a Promise that resolves to undefined.
 */
export const loadScriptFromSrc = ({ src, dataAttributes = {}, id } = {}) =>
  new Promise((resolve, reject) => {
    if (id && getElementById(id)) {
      return resolve()
    }
    const script = createElement('script')
    // Set the Source of the Script.
    script.src = src
    // Set the Data Attributes of the Scripts if any are passed
    const dataAttributesKeys = Object.keys(dataAttributes)
    for (
      let i = 0, dataAttributesKeysLen = dataAttributesKeys.length;
      i < dataAttributesKeysLen;
      i++
    ) {
      const dataAttributeKey = dataAttributesKeys[i]
      script.setAttribute(dataAttributeKey, dataAttributes[dataAttributeKey])
    }
    // Add the `load` Event Handler to Resolve the Promised when the Script is Loaded.
    script.addEventListener('load', function() {
      return resolve()
    })
    // Add the `error` Event Handler to Reject the Promised when the Script loading fails.
    script.addEventListener('error', function(err) {
      return reject(err)
    })
    // Attach the Script to the Body of the HTML to start Loading.
    return document.body.appendChild(script)
  })
