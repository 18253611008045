/**
 * Grabs the data we dump from the server into the DOM and exports them as a singleton.
 * This allows us to conveniently dump server data into the dom and use this to load
 * that data into our app.
 */
// because the way webpack is implemented, you can import this file anywhere
// in the codebase like so:
// import serverData from 'utils/server-data'
import { WorldReady } from '@paypalcorp/worldready'
const serverData = {}

function init() {
  const dataNode = document.getElementById('server-data')

  if (!dataNode) {
    console.error('There was now element with the id of server-data.')
    return
  }

  try {
    const serverDataString = dataNode.innerHTML
    Object.assign(serverData, JSON.parse(serverDataString))

    if (dataNode.parentElement) {
      dataNode.parentElement.removeChild(dataNode) // cleanup the DOM
    }

    // need this for geo-enablement and to have access to a dynamic paypal.com url
    // for example paypal.cn if in China
    if (serverData.cdnHost) {
      let domain = []
      let i = serverData.cdnHost.length - 1

      // serverData.cdnHost i.e. paypalobjects.com or objects.paypal.cn
      // @FIXME once geo deadline eases up
      while (serverData.cdnHost[i] !== '.') {
        domain.unshift(serverData.cdnHost[i])
        i--
      }

      serverData.domain = `paypal.${domain.join('')}`
    }

    if (process.env.NODE_ENV !== 'test') {
      // paypal-scripts will load worldReady with content automatically during tests
      WorldReady.load(serverData.worldReady)
    }
  } catch (err) {
    console.error('server-data: load', err)
  }
}

export default serverData

export { init }
/* eslint no-console:0 */
