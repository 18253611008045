import React, { Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { recordImpression } from '../utils/tracking'
import DefaultLayout from './default'
import Header from '../components/header'
import Footer from '../components/footer'
import Loader from '../components/loader'
import constants from '../constants'

import styles from './layouts.module.css'

export const LAYOUT_NAME = {
  MERCHANT_HEADER_APP_FOOTER: 'MERCHANT_HEADER_APP_FOOTER',
}

const Layout = ({
  isUserAuthenticated,
  children,
  match,
  location,
  context,
  countryLabel,
  currency,
}) => {
  // record a page impression when the URL changes
  useEffect(() => {
    let fptiItem = null
    if (match && match.params) {
      const { buttonId } = match.params
      fptiItem = { item: buttonId }
    }
    recordImpression({ ...fptiItem, ...context })
  }, [location.pathname])

  let header, footer
  // the consumer header/footer is loaded outside of the React app in the root index.html
  if (!constants.consumerAccountTypes.includes(context.accountType)) {
    header = Header
    footer = Footer
  }

  return (
    // @TODO - Loading Component Implementation
    <Suspense
      fallback={
        <Loader
          className={styles.PageLoadingContainer}
          type="fixed"
          size="lg"
          show={true}
          priority={9}
        />
      }
    >
      <DefaultLayout
        isUserAuthenticated={isUserAuthenticated}
        Header={header}
        Footer={footer}
      >
        <Suspense
          fallback={
            <Loader
              className={styles.PageLoadingContainer}
              size="lg"
              show={true}
              priority={10}
            />
          }
        >
          {React.cloneElement(children, { countryLabel, currency })}
        </Suspense>
      </DefaultLayout>
    </Suspense>
  )
}

Layout.propTypes = {
  isUserAuthenticated: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  match: PropTypes.object,
  location: PropTypes.object,
  context: PropTypes.object,
  countryLabel: PropTypes.string,
  currency: PropTypes.string,
}

export default withRouter(Layout)
