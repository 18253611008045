/* global PAYPAL */

import pick from 'lodash/pick'

// whitelist of properties (other than those explicitly passed in
// constructData) that will be sent to FPTI. Add to this list below if you
// want to send a new property. It's probably not a good idea to remove from
// this list unless you check the that property is not being sent anywhere.
const VALID_PROPERTIES = [
  'flfr',
  'link',
  'optsel',
  'erpg',
  'eccd',
  'status',
  'item',
  'item_amt',
  'amount',
  'amt_type',
]

const constructData = ({ accountType, entryPoint, user, ...options }) => {
  // take a URL pathname like: "/instantcommerce/shareproduct/WC63P4VG4W65Q"
  // and split it into its components, like: ["shareproduct", "WC63P4VG4W65Q"]
  const path = window.location.pathname
    .split('/')
    .filter(x => x)
    .splice(1)
  const page = ['main', 'merchanthub', 'myproduct', path[0]]
    .filter(x => x)
    .join(':')
  const entry_point =
    entryPoint || typeof entryPoint !== 'undefined'
      ? { entry_point: entryPoint }
      : null

  return {
    page,
    pgrp: page,
    flow: 'PP_COMMERCE_PLATFORM',
    prod: 'instant_commerce',
    acnt: accountType,
    cust: user && user.custId,
    ...entry_point,
    ...pick(options, VALID_PROPERTIES),
  }
}

let currentPath
export const recordImpression = options => {
  const { pathname } = window.location
  if (window.PAYPAL && window.PAYPAL.analytics && pathname !== currentPath) {
    PAYPAL.analytics.Analytics.prototype.recordImpression({
      data: constructData(options),
    })
    // keep track of the last URL path for which we sent an impression to avoid
    // sending duplicate events if a component is re-rendered on the same page
    currentPath = pathname
  }
}

export const recordClick = options => {
  if (window.PAYPAL && window.PAYPAL.analytics) {
    PAYPAL.analytics.Analytics.prototype.recordClick({
      data: constructData(options),
    })
  }
}
